

import React from "react";
import { Link } from "react-router-dom";

function FormatWidget({ title, subtitle, linkText, linkTo, children }) {
  return (
    <div
      className="lg:col-span-6  md:py-3 px-4 lg:px-20 2xl:px-[6%] 3xl:px-[12%] 4xl:px-[12%]"
      initial="hidden"
      animate="visible"
    >
      <div className="flex flex-col gap-8">
        <div className="flex flex-col md:flex-row md:items-center justify-between mb-4 gap-4">
          <div className="flex flex-col mr-2 gap-1">
            <h2
              className="font-bold text-base md:text-2xl"
            >
              {title}
            </h2>
            <h3
              className="leading-5 text-sm md:text-base font-extralight text-gray-500"
            >
              {subtitle}
            </h3>
          </div>
          {linkText && linkTo && (
            <div>
              <Link
                reloadDocument
                to={linkTo}
                className="p-1  md:w-32  flex justify-center text-black border-black border font-extralight hover:bg-black hover:text-white"
              >
                {linkText}
              </Link>
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

export default FormatWidget;
