import React from "react";
import FormatWidget from "./FormatWidget";
import Reviews from "./Reviews";

function ReviewsComponent({ viewAll }) { // Destructure viewAll from props
  return (
    <div>
      <FormatWidget
        title="REVIEWS"
        subtitle="Read trusted reviews from our customers."
        linkText={!viewAll ? "VIEW ALL" : null} // Show "VIEW ALL" only if not viewing all
        linkTo={!viewAll ? "/reviews" : null} // Link to /reviews only if not viewing all
      >
        <Reviews viewAll={viewAll} />
      </FormatWidget>
    </div>
  );
}

export default ReviewsComponent;
