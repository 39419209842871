import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

// import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Shimmer from "./components/Shimmer";
import NotFound from "./components/NotFound";
import ReviewsComponent from "./components/ReviewsComponent";
import { ToastContainer } from "react-toastify";

const Home = lazy(() => import("./pages/Home"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Developers = lazy(() => import("./pages/Developers"));
const XrTeam = lazy(() => import("./pages/XrTeam"));
const AllProjects = lazy(() => import("./components/AllProjects"));
const AllCommunities = lazy(() => import("./components/AllCommunities"));
const AllNewsInsights = lazy(() => import("./pages/AllNewsInsights"));
const ProjectDetails = lazy(() => import("./components/ProjectDetails"));
const OurCommunities = lazy(() => import("./components/OurCommunities"));
const AgentDetails = lazy(() => import("./components/AgentDetails"));
const RenderContent = lazy(() => import("./components/RenderContent"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const Navbar = lazy(() => import("./components/Navbar"));


const Wrapper = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <Router>
      <Wrapper>
        <Navbar />
        <Suspense fallback={<Shimmer />}>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about-us/" element={<AboutUs />} />
            <Route
              path="/contact-us-dubai-real-estate-agency/"
              element={<ContactUs />}
            />
            <Route path="/label/:id" element={<Developers />} />
            <Route path="/meet-the-xr/" element={<XrTeam />} />
            <Route path="/dubai-properties/" element={<AllProjects />} />
            <Route path="/communities" element={<AllCommunities />} />
            <Route path="/real-estate-news/" element={<AllNewsInsights />} />
            <Route path="/:title" element={<RenderContent />} />
            <Route path="/notfound" element={<NotFound />} />

            {/* imagesWithInfo={imagesWithInfo}
                  sections={projectDetails} */}
            <Route path="/property/:title" element={<ProjectDetails />} />

            {/*   imagesWithInfo={communityInfo}
                  nearbyCommunities={nearbyCommunities} */}
            <Route path="/area/:title" element={<OurCommunities />} />
            <Route path="/agent/:agentName" element={<AgentDetails />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/reviews" element={<ReviewsComponent viewAll={true} />} />
            
          </Routes>
        </Suspense>
      </Wrapper>
      <Footer />
      <ToastContainer />

    </Router>
  );
}

export default App;
